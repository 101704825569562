<template>
  <div ref="mainDiv" :class="!isEmbedded ? ['timeline-d'] : ['ineffective']">
    <div :class="!isEmbedded ? ['timeline-body-d'] : ['ineffective']">
      <div
        :class="
          !isEmbedded
            ? [
                'timeline-item-d',
                'timeline-item-d--extra',
                'timeline-item-d--extra--plus',
              ]
            : ['ineffective']
        "
      >
        <div class="content-d">
          <b-row v-if="showHeader === true" align-content="left">
            <b-col>
              <h2 class="info-box-d">
                {{ $t("home.post-types." + post.type) }}
              </h2>
              <span class="time-d">
                <HumanReadableTime :datetime="post.created_at" />
              </span>
            </b-col>
          </b-row>
          <hr v-if="showHeader === true" />
          <div
            v-if="showAuthor === true && $_.get(post, 'member') !== null"
            class="user-timeline-tag"
          >
            <b-row>
              <b-col cols="9">
                <b-row>
                  <b-col cols="2">
                    <b-avatar
                      variant="info"
                      :src="getAvatar(post.member.avatar, 65)"
                      class="img-fluid"
                      size="65"
                      style="height: 65px"
                    >
                    </b-avatar>
                  </b-col>
                  <b-col cols="10">
                    <a href="#" @click="getUserProfile(post.member.uid)" class="user-timeline-tag-text-name">
                      {{ post.member.name }}
                      {{ post.member.surname }}
                    </a>
                    <p class="user-timeline-tag-text-company">
                      {{ post.member.company.name }}
                    </p>
                  </b-col>
                </b-row>
              </b-col>

              <b-col cols="3">
                <a
                  v-if="GET_USER_INFO.id != post.member.id"
                  @click="startConversation(post.member.uid)"
                  href="javascript:void(0)"
                >
                  <b-img
                    src="/img/card-message-map.svg"
                    style="margin-left: 180px"
                  >
                  </b-img>
                </a>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="content-main-d" :id="'post-content-' + post.id">
          <div v-if="post.content.message" class="content-main-text-d">
            <p v-html="$_.replace(replaceLink(post.content.message), /(?:\r\n|\r|\n)/g, '<br/>')"></p>
          </div>
          <div
            style="cursor: pointer"
            @click="clickGraph(post.content.graph.url)"
            v-if="$_.get(post, 'content.graph.title')"
          >
            <!-- open graph bilgileri -->
            <b-row class="content-main-d__graph">
              <!-- repost modal view -->
              <b-col cols="3">
                <b-img
                  style="border-radius: 6px; margin-bottom: 10px"
                  v-if="post.content.graph.image"
                  @error="imgOnError(post)"
                  :src="CDN_IMAGE_PREFIX + '?url=' + post.content.graph.image"
                  width="250px"
                />
                <b-img v-else src="/img/no-image.png" width="250px" />
              </b-col>
              <b-col cols="9">
                <h6> 
                  <a :href="post.content.graph.url" target="_blank" v-html="post.content.graph.title"></a>
                </h6>
                <p>
                  <a :href="post.content.graph.url" target="_blank" v-html="post.content.graph.description"> </a>
                </p>
              </b-col>
            </b-row>
            <!-- opengraph bilgileri -->
          </div>
          <!--jimir3x-->
          <div
            class="content-main-d__media-files"
            v-if="$_.size(post.content.files) > 0"
          >
            <!-- 1 file -->
            <div
              v-if="$_.size(post.content.files) == 1"
              style="margin-top: 30px"
              class="content-main-d__media-files__inner--1"
            >
              <div
                style="margin-bottom: 10px"
                class="content-main-d__media-files__file-wrapper--1"
                v-for="(file, index) in post.content.files"
                :key="index"
                @click="() => showPostImageModal(index,  post.content.files)"
              >
                <img
                  class="member-post-img"
                  v-if="['jpeg', 'jpg', 'png'].includes(file.ext)"
                  :src="CDN_IMAGE_PREFIX + file.code + '.jpg'"
                />
                <video
                  v-else-if="['webm', 'mp4'].includes(file.ext)"
                  style="width: 100%; height:50vh; object-fit:contain; border-radius: 6px"
                  controls
                >
                  <source :src="CDN_VIDEO_PREFIX + file.code" />
                </video>
                <audio v-else-if="['mp3'].includes(file.ext)" controls>
                  <source
                    :src="CDN_VIDEO_PREFIX + file.code"
                    type="audio/mpeg"
                  />
                </audio>
                <img v-else src="@/assets/img/file.png" width="100" />
              </div>
            </div>
            <!-- 1 file -->

            <!-- 2 files -->
            <div
              v-if="$_.size(post.content.files) == 2"
              style="margin-top: 30px"
              class="content-main-d__media-files__inner--2"
            >
              <div
                style="margin-bottom: 10px"
                class="content-main-d__media-files__file-wrapper--2"
                v-for="(file, index) in post.content.files"
                :key="index"
                @click="() => showPostImageModal(index,  post.content.files)"
              >
                <img
                  class="member-post-img-repost"
                  v-if="['jpeg', 'jpg', 'png'].includes(file.ext)"
                  :src="CDN_IMAGE_PREFIX + file.code + '.jpg'"
                />
                <video
                  v-else-if="['webm', 'mp4'].includes(file.ext)"
                  style="width: 100%; border-radius: 6px"
                  controls
                >
                  <source :src="CDN_VIDEO_PREFIX + file.code" />
                </video>
                <audio v-else-if="['mp3'].includes(file.ext)" controls>
                  <source
                    :src="CDN_VIDEO_PREFIX + file.code"
                    type="audio/mpeg"
                  />
                </audio>
                <img v-else src="@/assets/img/file.png" width="100" />
              </div>
            </div>
            <!-- 2 files -->

            <!-- 3 files -->
            <div
              v-if="$_.size(post.content.files) == 3"
              style="margin-top: 30px"
              class="content-main-d__media-files__inner--3"
            >
              <div
                class="content-main-d__media-files__file-wrapper--3__left"
                @click="() => showPostImageModal(0,  post.content.files)"
              >
                <img
                  class="member-post-img"
                  v-if="
                    ['jpeg', 'jpg', 'png'].includes(post.content.files[0].ext)
                  "
                  :src="CDN_IMAGE_PREFIX + post.content.files[0].code + '.jpg'"
                />
                <video
                  v-else-if="
                    ['webm', 'mp4'].includes(post.content.files[0].ext)
                  "
                  style="width: 100%; border-radius: 6px"
                  controls
                >
                  <source
                    :src="CDN_VIDEO_PREFIX + post.content.files[0].code"
                  />
                </video>
                <audio
                  v-else-if="['mp3'].includes(post.content.files[0].ext)"
                  controls
                >
                  <source
                    :src="CDN_VIDEO_PREFIX + post.content.files[0].code"
                    type="audio/mpeg"
                  />
                </audio>
                <img v-else src="@/assets/img/file.png" width="100" />
              </div>
              <div class="content-main-d__media-files__file-wrapper--3__right">
                <div
                  class="
                    content-main-d__media-files__file-wrapper--3__right--top
                  "
                  @click="() => showPostImageModal(1,  post.content.files)"
                >
                  <img
                    class="member-post-img"
                    v-if="
                      ['jpeg', 'jpg', 'png'].includes(post.content.files[1].ext)
                    "
                    :src="
                      CDN_IMAGE_PREFIX + post.content.files[1].code + '.jpg'
                    "
                  />
                  <video
                    v-else-if="
                      ['webm', 'mp4'].includes(post.content.files[1].ext)
                    "
                    style="width: 100%; border-radius: 6px"
                    controls
                  >
                    <source
                      :src="CDN_VIDEO_PREFIX + post.content.files[1].code"
                    />
                  </video>
                  <audio
                    v-else-if="['mp3'].includes(post.content.files[1].ext)"
                    controls
                  >
                    <source
                      :src="CDN_VIDEO_PREFIX + post.content.files[1].code"
                      type="audio/mpeg"
                    />
                  </audio>
                  <img v-else src="@/assets/img/file.png" width="100" />
                </div>

                <div
                  class="
                    content-main-d__media-files__file-wrapper--3__right--bottom
                  "
                  @click="() => showPostImageModal(2,  post.content.files)"
                >
                  <img
                    class="member-post-img"
                    v-if="
                      ['jpeg', 'jpg', 'png'].includes(post.content.files[2].ext)
                    "
                    :src="
                      CDN_IMAGE_PREFIX + post.content.files[2].code + '.jpg'
                    "
                  />
                  <video
                    v-else-if="
                      ['webm', 'mp4'].includes(post.content.files[2].ext)
                    "
                    style="width: 100%; border-radius: 6px"
                    controls
                  >
                    <source
                      :src="CDN_VIDEO_PREFIX + post.content.files[2].code"
                    />
                  </video>
                  <audio
                    v-else-if="['mp3'].includes(post.content.files[2].ext)"
                    controls
                  >
                    <source
                      :src="CDN_VIDEO_PREFIX + post.content.files[2].code"
                      type="audio/mpeg"
                    />
                  </audio>
                  <img v-else src="@/assets/img/file.png" width="100" />
                </div>
              </div>
            </div>
            <!-- 3 files -->

            <!-- 4 or more files -->
            <div
              v-if="$_.size(post.content.files) > 3"
              style="margin-top: 30px"
              class="content-main-d__media-files__inner--4"
            >
              <div
                class="content-main-d__media-files__file-wrapper--4__left"
                @click="() => showPostImageModal(0,  post.content.files)"
              >
                <img
                  class="member-post-img"
                  v-if="
                    ['jpeg', 'jpg', 'png'].includes(post.content.files[0].ext)
                  "
                  :src="CDN_IMAGE_PREFIX + post.content.files[0].code + '.jpg'"
                />
                <video
                  v-else-if="
                    ['webm', 'mp4'].includes(post.content.files[0].ext)
                  "
                  style="width: 100%; border-radius: 6px"
                  controls
                >
                  <source
                    :src="CDN_VIDEO_PREFIX + post.content.files[0].code"
                  />
                </video>
                <audio
                  v-else-if="['mp3'].includes(post.content.files[0].ext)"
                  controls
                >
                  <source
                    :src="CDN_VIDEO_PREFIX + post.content.files[0].code"
                    type="audio/mpeg"
                  />
                </audio>
                <img v-else src="@/assets/img/file.png" width="100" />
              </div>

              <div class="content-main-d__media-files__file-wrapper--4__right">
                <div
                  class="
                    content-main-d__media-files__file-wrapper--4__right--top
                  "
                  @click="() => showPostImageModal(1,  post.content.files)"
                >
                  <img
                    class="member-post-img"
                    v-if="
                      ['jpeg', 'jpg', 'png'].includes(post.content.files[1].ext)
                    "
                    :src="
                      CDN_IMAGE_PREFIX + post.content.files[1].code + '.jpg'
                    "
                  />
                  <video
                    v-else-if="
                      ['webm', 'mp4'].includes(post.content.files[1].ext)
                    "
                    style="width: 100%; border-radius: 6px"
                    controls
                  >
                    <source
                      :src="CDN_VIDEO_PREFIX + post.content.files[1].code"
                    />
                  </video>
                  <audio
                    v-else-if="['mp3'].includes(post.content.files[1].ext)"
                    controls
                  >
                    <source
                      :src="CDN_VIDEO_PREFIX + post.content.files[1].code"
                      type="audio/mpeg"
                    />
                  </audio>
                  <img v-else src="@/assets/img/file.png" width="100" />
                </div>
                <div
                  class="
                    content-main-d__media-files__file-wrapper--4__right--bottom
                  "
                >
                  <div
                    class="
                      content-main-d__media-files__file-wrapper--4__right__bottom--left
                    "
                    @click="() => showPostImageModal(2,  post.content.files)"
                  >
                    <img
                      class="member-post-img"
                      v-if="
                        ['jpeg', 'jpg', 'png'].includes(
                          post.content.files[2].ext
                        )
                      "
                      :src="
                        CDN_IMAGE_PREFIX + post.content.files[2].code + '.jpg'
                      "
                    />
                    <video
                      v-else-if="
                        ['webm', 'mp4'].includes(post.content.files[2].ext)
                      "
                      style="width: 100%; border-radius: 6px"
                      controls
                    >
                      <source
                        :src="CDN_VIDEO_PREFIX + post.content.files[2].code"
                      />
                    </video>
                    <audio
                      v-else-if="['mp3'].includes(post.content.files[2].ext)"
                      controls
                    >
                      <source
                        :src="CDN_VIDEO_PREFIX + post.content.files[2].code"
                        type="audio/mpeg"
                      />
                    </audio>
                    <img v-else src="@/assets/img/file.png" width="100" />
                  </div>
                  <div
                    class="
                      content-main-d__media-files__file-wrapper--4__right__bottom--right
                    "
                    @click="() => showPostImageModal(3,  post.content.files)"
                  >
                    <img
                      class="member-post-img"
                      v-if="
                        ['jpeg', 'jpg', 'png'].includes(
                          post.content.files[3].ext
                        )
                      "
                      :src="
                        CDN_IMAGE_PREFIX + post.content.files[3].code + '.jpg'
                      "
                    />
                    <video
                      v-else-if="
                        ['webm', 'mp4'].includes(post.content.files[3].ext)
                      "
                      style="width: 100%; border-radius: 6px"
                      controls
                    >
                      <source
                        :src="CDN_VIDEO_PREFIX + post.content.files[3].code"
                      />
                    </video>
                    <audio
                      v-else-if="['mp3'].includes(post.content.files[3].ext)"
                      controls
                    >
                      <source
                        :src="CDN_VIDEO_PREFIX + post.content.files[3].code"
                        type="audio/mpeg"
                      />
                    </audio>
                    <img v-else src="@/assets/img/file.png" width="100" />
                    <div
                      v-if="$_.size(post.content.files) > 4"
                      class="content-main-d__media-files__overlay"
                    >
                      <span>+{{ $_.size(post.content.files) - 4 }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 4 or more files -->
          </div>
          <!--jimir3x-->
          <div v-if="post.type === 'PRODUCT'">
            <!-- product bilgileri -->
            <b-row>
              <b-col cols="3">
                <b-carousel
                  id="carousel-no-animation"
                  style="text-shadow: 0px 0px 2px #000"
                  no-animation
                  indicators
                  img-width="1024"
                  img-height="480"
                >
                  <b-carousel-slide
                    v-for="(img, index) in post.content.ref.images"
                    :key="index"
                    :img-src="CDN_IMAGE_PREFIX + img + '?size=525xauto'"
                  >
                  </b-carousel-slide>
                </b-carousel>
              </b-col>
              <b-col cols="9">
                <b-row>
                  <b-col cols="12" class="mb-1">
                    <h6> <span style="text-transform:uppercase !important;" >
                      {{ findSubItemByLang(post.content.ref.contents).name }}
                      </span>
                    </h6>
                    <p class="mb-20">
                      {{ $t("myquotes.id") }}:
                      {{ post.content.ref.product_id }}
                    </p>
                    <h6 class="post-text">
                      <nl2br
                        class="content-description"
                        tag="p"
                        :text="
                          findSubItemByLang(post.content.ref.contents).description
                        "
                      />
                    </h6>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-row style="display:flex; justify-content:space-between">
                      <b-col cols="4">
                        <b-row style="margin-left: 0px">
                          <p class="quotations-column-content">
                            {{ $t("myquotes.ppu") }}
                          </p>
                        </b-row>
                        <b-row style="margin-left: 0px">
                          <div class="upload-data-home">
                            <p class="upload-data-home-text">
                              {{ post.content.ref.currency.symbol }}
                              {{ post.content.ref.min_price }}
                              -
                              <!-- {{ post.content.ref.currency.symbol }} -->
                              {{ post.content.ref.max_price }}
                              <span class="ton-color">
                                /
                                {{ post.content.ref.unit.shortname }}
                              </span>
                            </p>
                          </div>
                        </b-row>
                      </b-col>
                      <b-col cols="4">
                        <b-row>
                          <p class="quotations-column-content">
                            {{ $t("myquotes.moq") }}
                          </p>
                        </b-row>
                        <b-row>
                          <div class="upload-data-home">
                            <p class="upload-data-home-text">
                              <span>{{ numberFixer(post.content.ref.moq) }}</span>
                              <span class="ton-color">
                                {{ post.content.ref.unit.name }}
                              </span>
                            </p>
                          </div>
                        </b-row>
                      </b-col>

                      <b-col cols="4">
                        <b-row>
                          <p class="quotations-column-content">
                            {{ $t("myquotes.capacity") }}
                          </p>
                        </b-row>
                        <b-row>
                          <div class="upload-data-home">
                            <p class="upload-data-home-text">
                              <span>
                                {{ numberFixer(post.content.ref.stock) }}
                                <span class="ton-color">
                                  {{ post.content.ref.unit.shortname }}
                                  /
                                  {{ $t("mw.general.month") }}
                                </span>
                              </span>
                            </p>
                          </div>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <!-- product bilgileri -->
          </div>
          <div v-if="post.type === 'INQUIRY'">
            <!-- inquiry bilgileri -->
            <h6> <span style="text-transform:uppercase !important;">
              {{ findSubItemByLang(post.content.ref.contents).name }}
              </span>
            </h6>
            <b-row>
              <b-col cols="12" class="mb-5">
                <h6 class="post-text">
                  <nl2br
                    class="ton-color"
                    tag="p"
                    :text="findSubItemByLang(post.content.ref.contents).description"
                  />
                </h6>
              </b-col>
            </b-row>
          
             
              <b-row>
                <b-col cols="4" class="industry-icon-requests d-flex">
                    <industry-icon
                    :color="'red'"
                    :name="post.content.ref.industry.icon"
                    />
                    <p class="margin-left:10px">
                    {{ post.content.ref.industry.name }}
                    </p>
                </b-col>
              </b-row>

              <hr>

                <b-row>
                 <b-col cols="12">
                <b-row cols="12" align-content="middle">
                  <b-col cols="2" style="margin-right: -40px">
                    {{ $t("myrequests.unit") }}
                  </b-col>
                  <b-col cols="3">
                    <div class="upload-data-home">
                      <p
                        class="upload-data-home-text"
                        style="text-align: center"
                      >
                        <span>
                          {{ post.content.ref.unit.name }}
                        </span>
                      </p>
                    </div>
                  </b-col>
                  <b-col cols="2" style="margin-right: -40px">
                    {{ $t("myrequests.demand") }}
                  </b-col>
                  <b-col cols="3">
                    <div class="upload-data-home">
                      <p
                        class="upload-data-home-text"
                        style="text-align: center"
                      >
                        <span>
                          {{ numberFixer(post.content.ref.demand) }}
                        </span>
                      </p>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
               </b-row>
             
           
            <!-- inquiry bilgileri -->
          </div>
          <!-- repost timeline div -->
          <div
            class="repost-timeline"
            v-if="post.content.ref && post.content.ref.__typename === 'Post'"
          >
            <div style="width: 90%; margin-top: 20px; height: 10px; backgroung: black;"></div>
            <hr style="width: 90%; margin-top: 10px" />
            <member-post
              :post="post.content.ref"
              :interactionBar="false"
              :showAuthor="true"
              :showHeader="false"
              :isEmbedded="true"
              @showPostImageModal="({ index, files }) => showPostImageModal(index, files)"
            />
          </div>
          <!-- repost timeline div -->
        </div>
        <!-- <hr style="width:80%;"> -->
        <div v-if="interactionBar === true" class="interaction-bar">
          <b-row>
            <b-col offset="9" cols="3">
              <b-row>
                <b-col cols="2" offset="2" class="d-flex justify-content-end">
                  <a 
                    href="javascript:void(0)"
                    @click="share(post.id)"
                  >
                    <svg style="width:14px;height:14px; color:#a7a7a7" class="zoom-icon" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M18 16.08C17.24 16.08 16.56 16.38 16.04 16.85L8.91 12.7C8.96 12.47 9 12.24 9 12S8.96 11.53 8.91 11.3L15.96 7.19C16.5 7.69 17.21 8 18 8C19.66 8 21 6.66 21 5S19.66 2 18 2 15 3.34 15 5C15 5.24 15.04 5.47 15.09 5.7L8.04 9.81C7.5 9.31 6.79 9 6 9C4.34 9 3 10.34 3 12S4.34 15 6 15C6.79 15 7.5 14.69 8.04 14.19L15.16 18.34C15.11 18.55 15.08 18.77 15.08 19C15.08 20.61 16.39 21.91 18 21.91S20.92 20.61 20.92 19C20.92 17.39 19.61 16.08 18 16.08M18 4C18.55 4 19 4.45 19 5S18.55 6 18 6 17 5.55 17 5 17.45 4 18 4M6 13C5.45 13 5 12.55 5 12S5.45 11 6 11 7 11.45 7 12 6.55 13 6 13M18 20C17.45 20 17 19.55 17 19S17.45 18 18 18 19 18.45 19 19 18.55 20 18 20Z" />
                    </svg>
                  </a>
                </b-col>
                <b-col cols="2" class="d-flex justify-content-end">
                  <a
                    v-if="post.liked === false"
                    @click="like()"
                    href="javascript:void(0)"
                  >
                    <!-- like edilmemiÅŸse -->
                    <b-img
                      class="zoom-icon"
                      size="15px"
                      style="height: 15px"
                      src="/img/like-passive.svg"
                    />
                    <span>{{ post.like_count }}</span>
                  </a>
                  <a v-else @click="unlike()" href="javascript:void(0)">
                    <!-- like edilmiÅŸse -->
                    <b-img
                      class="zoom-icon"
                      size="15"
                      style="height: 15px"
                      src="/img/like.svg"
                    />
                    <span>{{ post.like_count }}</span>
                  </a>
                </b-col>
                <b-col cols="2" class="d-flex justify-content-end">
                  <a v-if="post.reposted" href="javascript:void(0)">
                    <b-img
                      class="zoom-icon"
                      size="15"
                      style="height: 15px"
                      src="/img/repost-blue.svg"
                    />
                  </a>
                  <a v-else @click="setParent()" href="javascript:void(0)">
                    <b-img
                      class="zoom-icon"
                      size="15"
                      style="height: 15px"
                      src="/img/repost-passive.svg"
                    />
                  </a>
                </b-col>
                <b-col cols="2" class="d-flex justify-content-end">
                  <a
                    v-if="post.pinned === false"
                    @click="pin()"
                    href="javascript:void(0)"
                  >
                    <!-- pin edilmemiÅŸse -->
                    <b-img
                      class="zoom-icon"
                      size="15"
                      style="height: 15px"
                      src="/img/pin-passive.svg"
                    />
                  </a>
                  <a v-else @click="unpin()" href="javascript:void(0)">
                    <!-- pin edilmiÅŸse -->
                    <b-img
                      class="zoom-icon"
                      size="15"
                      style="height: 15px"
                      src="/img/pin.svg"
                    />
                  </a>
                </b-col>
                <b-col cols="2" class="d-flex justify-content-end">
                  <a
                    v-if="$_.get(post, 'member.id') === GET_USER_INFO.id"
                    @click="remove()"
                    href="javascript:void(0)"
                  >
                    <b-img
                      class="zoom-icon"
                      size="15"
                      style="height: 15px"
                      src="/img/delete-icon.svg"
                    />
                  </a>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HumanReadableTime from "@/components/global/HumanReadableTime.vue";
import LINK_REGEX from "@/consts/link-regex";
import isIntoView from "@/helpers/is-into-view";
import _ from "lodash";
export default {
  name: "member-post",
  props: {
    post: {
      type: Object,
      default: {},
      required: true,
    },

    interactionBar: {
      type: Boolean,
      default: true,
      required: false,
    },

    showAuthor: {
      type: Boolean,
      default: true,
      required: false,
    },

    showHeader: {
      type: Boolean,
      default: true,
      required: false,
    },

    isEmbedded: {
      type: Boolean,
      default: false,
      required: false,
    },

    eventBus: {
      type: Object,
      required: false,
    },
  },
  components: {
    HumanReadableTime,
  },
  data() {
    return {};
  },
  methods: {
    imgOnError(post) {
      post.content.graph.image = "/img/no-image.png";
    },
    replaceLink(message) {
      return _.replace(
        message,
        LINK_REGEX,
        '<a href="$1" target="_blank">$1</a>'
      );
    },

    setParent() {
      let cond = (
          this.post.content.ref
          &&
          this.post.content.ref.__typename == 'Post'
      );

      this.$emit("setParent", cond ? this.post.content.ref : this.post);
    },

    pin() {
      this.$emit("pin", this.post.id);
    },

    unpin() {
      this.$emit("unpin", this.post.id);
    },

    like() {
      this.$emit("like", this.post.id);
    },

    unlike() {
      this.$emit("unlike", this.post.id);
    },

    remove() {
      this.$emit("remove", this.post.id);
    },

    /*jimir3x*/
    showPostImageModal(index, files) {
      this.$emit("showPostImageModal", { files, index });
    },
    /*jimir3x*/

    checkView() {
      if (
        this.post.viewed == false &&
        _.isNil(this.$refs.mainDiv) === false &&
        isIntoView(this.$refs.mainDiv)
      ) {
        this.$store.dispatch("landing/post/VIEW", this.post.id);
      }
    },

    share() {
        this.$emit('share', this.post.id);
    },

    clickGraph(url) {
      window.open(url, "_blank");
    },
  },

  mounted() {
    this.checkView();
    if (this.eventBus) {
      this.eventBus.$on("scroll", () => this.checkView());
    }
  },
};
</script>