<template>
    <div class="timeline-d">
        <div class="timeline-body-d">
            <div class="timeline-item-d">
                <div class="content-d">
                    <b-row >
                        <h2 class="info-box-d">{{$t("home.welcome")}}</h2>
                        <!-- <span class="time-d">{{$t("home.just")}}</span> -->
                    </b-row>

                    <b-row class="welcome-logo-d">
                        <img
                            src="/img/logo.svg"
                            alt=""
                            class="img-fluid"
                        />
                    </b-row>

                    <!-- ////////////////ANA ICREIKLER//////////////////// -->
                </div>

                <div class="content-main-d">
                    <div class="content-main-text-d">
                        <p>
                            {{$t("home.hi")}} {{ GET_USER_INFO.name }},
                            <br />
                            <br />
                            <span>
                                <span>{{$t("home.wel-p1")}}</span>
                                <br />
                                <br />
                                <span
                                    >{{$t("home.wel-p2")}}
                                </span>
                            </span>
                            <br />
                            <br />
                            <b-row>
                                <b-col
                                    cols="1"
                                    class="text-center"
                                >
                                    <b-img
                                        src="/img/account-icons.png"
                                    >
                                    </b-img>
                                </b-col>

                                <b-col cols="11">
                                    <p>
                                    {{$t("home.wel-p3")}}
                                    </p>
                                </b-col>
                            </b-row>

                            <br />
                            <b-row
                                rows="12"
                                class="justify-content-midle"
                            >
                                <!--jimir3x-->
                                <b-col style="display:flex;justify-content:center;align-items:center;">
                                <b-img src="/img/switch-account.svg"/>
                                <p style="display:inline-flex;align-items:center;margin:0 0 0 12px;">
                                    {{$t("home.buyer")}} / {{$t("home.seller")}}
                                </p>
                                </b-col>
                                <!--jimir3x-->
                            </b-row>
                        </p>

                        <p>
                            {{$t("home.comprate")}} :
                            <br />
                            <br />
                            <span>
                                <span>{{$t("home.wel-p4")}}</span>
                                <br />
                                <br />
                                <span>{{$t("home.wel-p5")}}</span>
                            </span>
                            <br />
                            <br />
                            <br />
                            <span>{{$t("home.team")}}</span>
                        </p>
                    </div>
                    <hr>
                    <div class="" style="margin-bottom: 0px; margin-top:10px;">
                    <b-form-checkbox
                        :value="true"
                        :unchecked-value="false"
                        @change="change"
                        class="welcome-clear-checkbox"
                        
                    >
                       <p  class="welcome-clear" > {{$t("home.clear")}} </p>
                    </b-form-checkbox>
                </div>
                </div>
                
            </div>
        </div>
    </div>
</template>
<script>
export default {
    methods: {
        change(value) {
            if (value === true) {
                this.$store.dispatch("landing/post/SET_DO_NOT_SHOW_AGAIN");
            }
        }
    }
}
</script>