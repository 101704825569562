<template>
    <div>
        <div class="content-d">
            <div v-if="$_.get(post, 'member') !== null" class="user-timeline-tag">
                <b-row>
                    <b-col cols="9">
                        <b-row>
                            <b-col cols="2">
                                <b-avatar
                                    variant="info"
                                    :src="getAvatar(post.member.avatar)"
                                    class="img-fluid"
                                    size="65"
                                   style="height:65px"
                                >
                                </b-avatar>
                            </b-col>
                            <b-col cols="10">
                                <p class="user-timeline-tag-text-name">
                                    {{ post.member.name }} {{ post.member.surname }}
                                </p>
                                <p class="user-timeline-tag-text-company">
                                    {{ post.member.company.name }}
                                </p>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="3">

                    </b-col>
                </b-row>
            </div>
        </div>
        <div class="repost-modal-main-d">
            <div v-if="post.content.message" class="content-main-text-d">
                <!-- status -->
                <p v-html="$_.replace(replaceLink(post.content.message), /(?:\r\n|\r|\n)/g, '<br/>')"></p>
            </div>
            <div v-if="$_.get(post, 'content.graph.title')">
                <!-- open graph bilgileri -->
                <b-row class="repost-modal-view">
                    <b-col cols="3">
                        <b-img style=" border-radius: 6px;" v-if="post.content.graph.image" @error="imgOnError(post)" :src="post.content.graph.image" width="100px" />
                        <b-img v-else src="/img/no-image.png" width="250px" />
                    </b-col>
                    <b-col cols="9">
                        <h6><a :href="post.content.graph.url" target="_blank">{{ post.content.graph.title }}</a></h6>
                        <p><a :href="post.content.graph.url" target="_blank">{{ post.content.graph.description }}</a></p>
                    </b-col>
                </b-row>
                <!-- opengraph bilgileri -->
            </div>
            <div v-if="$_.size(post.content.files) > 0">
                <!-- files -->
                <div 
                    style="margin-bottom: 10px; padding-top: 5px"
                    v-for="(file, index) in post.content.files"
                    :key="index"
                >
                    <img
                        v-if="['jpeg', 'jpg', 'png'].includes(file.ext)"
                        :src="CDN_IMAGE_PREFIX + file.code + '.jpg'"
                        width="100%"
                        style="border-radius:6px;"
                    />
                    <video
                        v-else-if="['webm', 'mp4'].includes(file.ext)"
                        style="width: 100%; border-radius:6px;"
                        controls
                    >
                        <source :src="CDN_VIDEO_PREFIX + file.code" />

                    </video>
                    <audio v-else-if="['mp3'].includes(file.ext)" controls>
                        <source :src="CDN_VIDEO_PREFIX + file.code" type="audio/mpeg" />
                    </audio>
                    <img v-else src="@/assets/img/file.png" width="100" />
                </div>
                <!-- file -->
            </div>
            <!-- parent post -->
            <div v-if="post.type === 'PRODUCT'">
                <b-row>
                    <b-col cols="3">
                        <b-carousel
                            id="carousel-no-animation"
                            style="text-shadow: 0px 0px 2px #000"
                            no-animation
                            indicators
                            img-width="1024"
                            img-height="480"
                        >
                            <b-carousel-slide
                                v-for="(img, index) in post.content.ref.images"
                                :key="index"
                                :img-src="CDN_IMAGE_PREFIX + img + '?size=525xauto'"
                            >
                            </b-carousel-slide>
                        </b-carousel>
                    </b-col>
                    <b-col cols="9">
                        <b-row>
                            <b-col cols="12" class="mb-1">
                                <h6> <span style="text-transform:uppercase !important;">
                                    {{ getDefault(post.content.ref.contents).name }}
                                    </span>
                                </h6>
                                <p class="mb-20">
                                    {{ $t("myquotes.id") }}:
                                    {{ post.content.ref.product_id }}
                                </p>
                                <h6 class="post-text">
                                    <nl2br
                                        class="content-description"
                                        tag="p"
                                        :text="
                                        getDefault(post.content.ref.contents).description
                                        "
                                    />
                                </h6>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12">
                                <b-row>
                                    <b-col cols="6">
                                        <b-row style="margin-left: 0px">
                                            <p class="quotations-column-content">
                                                {{ $t("myquotes.ppu") }}
                                            </p>
                                        </b-row>
                                        <b-row style="margin-left: 0px">
                                            <div class="upload-data-home">
                                                <p class="upload-data-home-text">
                                                    {{ post.content.ref.currency.symbol }}
                                                    {{ post.content.ref.min_price }}
                                                    -
                                                    <!-- {{ post.content.ref.currency.symbol }} -->
                                                    {{ post.content.ref.max_price }}
                                                    <span class="ton-color">
                                                        /
                                                        {{ post.content.ref.unit.shortname }}
                                                    </span>
                                                </p>
                                            </div>
                                        </b-row>
                                    </b-col>
                                    <b-col cols="6">
                                        <b-row>
                                            <p class="quotations-column-content">
                                                {{ $t("myquotes.moq") }}
                                            </p>
                                        </b-row>
                                        <b-row>
                                            <div class="upload-data-home">
                                                <p class="upload-data-home-text">
                                                    <span>{{ numberFixer(post.content.ref.moq) }}</span>
                                                    <span class="ton-color">
                                                        {{ post.content.ref.unit.name }}
                                                    </span>
                                                </p>
                                            </div>
                                        </b-row>
                                    </b-col>

                                    <!-- <b-col cols="4">
                                        <b-row>
                                            <p class="quotations-column-content">
                                                {{ $t("myquotes.capacity") }}
                                            </p>
                                        </b-row>
                                        <b-row>
                                            <div class="upload-data-home">
                                                <p class="upload-data-home-text">
                                                    <span>
                                                        {{ post.content.ref.stock }}
                                                        <span class="ton-color">
                                                        {{ post.content.ref.unit.shortname }}
                                                        /
                                                        {{ $t("mw.general.month") }}
                                                        </span>
                                                    </span>
                                                </p>
                                            </div>
                                        </b-row>
                                    </b-col> -->
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            <!-- product bilgileri -->
          </div>
          <div v-if="post.type === 'INQUIRY'">
            <!-- inquiry bilgileri -->
            <h6><span style="text-transform:uppercase !important;">
                {{ getDefault(post.content.ref.contents).name }}
                </span>
            </h6>
            <b-row>
              <b-col cols="12" class="mb-5">
                <h6 class="post-text">
                  <nl2br class="ton-color" tag="p" :text="getDefault(post.content.ref.contents).description"/>
                </h6>
              </b-col>
            </b-row>
            <b-row>
                <b-col cols="4" class="industry-icon-requests d-flex">
                    <industry-icon
                        :color="'red'"
                        :name="post.content.ref.industry.icon"
                    />
                    <p class="margin-left:10px">
                        {{ post.content.ref.industry.name }}
                    </p>
                </b-col>
            </b-row>
            <hr>
            <b-row>
                <b-col cols="12">
                <b-row cols="12" align-content="middle">
                  <b-col cols="2" style="margin-right: -40px">
                    {{ $t("myrequests.unit") }}
                  </b-col>
                  <b-col cols="3">
                    <div class="upload-data-home">
                      <p
                        class="upload-data-home-text"
                        style="text-align: center"
                      >
                        <span>
                          {{ post.content.ref.unit.name }}
                        </span>
                      </p>
                    </div>
                  </b-col>
                  <b-col cols="2" style="margin-right: -40px">
                    {{ $t("myrequests.demand") }}
                  </b-col>
                  <b-col cols="3">
                    <div class="upload-data-home">
                      <p
                        class="upload-data-home-text"
                        style="text-align: center"
                      >
                        <span>
                          {{ numberFixer (post.content.ref.demand) }}
                        </span>
                      </p>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
            <!-- parent post -->
        </div>
    </div>
</template>
<script>
import LINK_REGEX from '@/consts/link-regex';
export default {
    name: 'member-post',
    props: {
        post: {
            type: Object,
            default: {},
            required: true
        }
    },
    components: {
    },
    data() {
        return {       
        }
    },
    methods: {
        imgOnError(post) {
            post.content.graph.image = '/img/no-image.png';
        },
        
        replaceLink(message) {
            return _.replace(message, LINK_REGEX, '<a href="$1" target="_blank">$1</a>');
        }
    },

    mounted() {
        
    }
}
</script>
