<template>
    <span>{{ value }}</span>
</template>
<script>
export default {
    name: 'counter',
    props: {
        number: {
            type: Number,
            default: 0,
            required: true
        }
    },

    data() {
        return {
            value: 0
        }
    },

    watch: {
        number() {
            this.run();
        }
    },

    methods: {
        async run() {
            if (this.value < this.number) {
                while (this.value < this.number) {
                    this.value++;
                    await this.tick();
                }          
            }

            if (this.value > this.number) {
                while (this.value > this.number) {
                    this.value--;
                    await this.tick();
                }
            }
        },

        tick() {
            return new Promise(resolve => setTimeout(resolve, 20));
        }
    },

    mounted() {
        this.run();
    }
}
</script>