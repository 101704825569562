<template>
    <div>{{ rhtime }}</div>
</template>

<script>
import moment from 'moment';
export default {
    props: {
        datetime: {
            type: [String, Date],
            required: true
        }
    },

    data() {
        return {
            rhtime: '',
            interval: null
        }
    },

    mounted() {
        this.rhtime = moment(this.datetime).fromNow();
        if (new Date().getTime() - moment(this.datetime).toDate().getTime() > (2 * 24 * 60 * 60 * 1000)) {
            return;
        }

        this.interval = setInterval(() => this.rhtime = moment(this.datetime).fromNow(),  60 * 1000);
    },

    beforeDestroy() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }
}
</script>