<template>
  <div id="landingSention">
    <Header />
    <div v-if="featureLoadings.process === true" class="loader-box zoom">
      <loading :loading="true" />
    </div>
    <div v-if="loadings.query === true" class="loader-box">
      <loading :loading="true" />
    </div>
    <b-container class="landing container-nar">
      <b-row class="justify-content-end">
        <button
          @click="() => (recommendShow = !recommendShow)"
          class="btn btn-sm btn-primary justify-content-end hide-show-landing"
          style="
            padding: 0px 10px;
            margin-right: 15px;
            font-size: 12px;
            margin: 6px 13px 6px 10px;
          "
        >
          {{ recommendShow ? $t("header.hide") : $t("header.show") }}
        </button>
      </b-row>
      <b-row class="landing-container d-flex">
        <b-col xl="3" lg="3" md="3" sm="12" class="home__left-side">
          <b-row>
            <b-col cols="12" class="">
              <div class="profile-card zoom radius">
                <b-row>
                  <b-col cols="5" style="max-width: 30%">
                    <b-avatar
                      variant="info"
                      :src="GET_USER_INFO.avatar"
                      class="img-fluid"
                      size="100"
                      style="height: 65px; width: 65px"
                    ></b-avatar>
                  </b-col>
                  <b-col cols="7">
                    <h3 style="text-transform: capitalize">
                      {{ shorter(GET_USER_INFO.name, GET_USER_INFO.surname) }}
                    </h3>
                    <h4>{{ GET_USER_INFO.title.name }}</h4>
                    <h5>
                      {{ GET_USER_INFO.company.name }}
                    </h5>
                  </b-col>
                </b-row>
                <hr />
                <b-row class="profile-card-content">
                  <b-col cols="10">
                    <router-link to="/app/my/connections">
                      <h6>{{ $t("home.connections") }}</h6></router-link
                    >
                  </b-col>
                  <b-col cols="2" class="landing-numbers">
                    <a> {{ assets.connections }} </a>
                  </b-col>
                  <b-col cols="10">
                    <a href="#" @click.prevent="gotoMyQuotes()">
                      <h6>{{ $t("home.my-quotes") }}</h6>
                    </a>
                  </b-col>
                  <b-col cols="2" class="landing-numbers">
                    <a> {{ assets.products }} </a>
                  </b-col>
                  <b-col cols="10">
                    <a href="#" @click.prevent="gotoMyRequests()">
                      <h6 style="margin-bottom: 10px !important">{{ $t("home.my-requests") }}</h6>
                    </a>
                  </b-col>
                  <b-col cols="2" class="landing-numbers">
                    <a> {{ assets.inquiries }} </a>
                   
                  </b-col>


                  <b-col cols="12">
                    <hr>
                    <!-- <router-link
                      to="/app/dashboard"
                      v-bind:class="{
                        selected: $route.path == '/app/dashboard',
                      }"
                    > -->
                      <h6 style="margin-bottom: 20px !important">
                        {{ $t("home.t-match") }}
                      </h6>
                    <!-- </router-link> -->
                  </b-col>

                 

                  <b-col cols="10">
                   <a @click.prevent="goToMatches('SELLER')" href="#">
                      <p class="buyer-seller-matches">
                        {{ $t("buttons.buyers") }}
                      </p>
                    </a>
                  </b-col>

                  <b-col cols="2" class="landing-numbers">
                    <a>{{ assets.matchings.seller }}  </a>
                  </b-col>

                  <b-col cols="10">
                    <a @click.prevent="goToMatches('BUYER')" href="#">
                      <p class="buyer-seller-matches">
                        {{ $t("buttons.sellers") }}
                      </p>
                    </a>
                  </b-col>

                  <b-col cols="2" class="landing-numbers">
                    <a> {{ assets.matchings.buyer }} </a>
                  </b-col>
                </b-row>
                <hr />
                <!--jimir3x-->
                <b-row
                  class="
                    profile-card-content-plan profile-card-content-plan--extra
                  "
                >
                  <div class="profile-card-content-plan__plan">
                    <h6>{{ $t("home.plan") }}:</h6>
                    <span>{{ plan.name }}</span>
                  </div>
                  <b-button
                    v-if="plan.upgrade"
                    class="profile-upgrade"
                    variant="outline-primary"
                    href="/app/profile/settings/plan"
                    style="zoom: 0.8; border-radius: 100px"
                  >
                    {{ $t("Upgrade") }}
                    <!--{{$t("buttons.upgrade")}}-->
                  </b-button>
                </b-row>
                <!--jimir3x-->
              </div>
            </b-col>
          </b-row>

          <!--jimir3x-->
          <b-row class="landing-row-sticky">
            <!--jimir3x-->
            <b-col cols="12">
              <div class="compatibility-card zoom radius">
                <h2 class="compatibility-card__title">
                  {{ $t("home.comprate") }}
                </h2>

                <b-row class="compcompatibility-rating__container zoom">
                  <b-col cols="9">
                    <b-progress
                      :value="cras.compatibility"
                      :max="100"
                      class="mb-3 progress-comp"
                    ></b-progress>
                  </b-col>
                  <b-col cols="3">
                    <span> %{{ cras.compatibility }} </span>
                  </b-col>
                </b-row>
                <hr v-if="$_.size(cras.suggestions) > 0" />
                <b-row v-if="$_.size(cras.suggestions) > 0">
                  <b-col cols="12">
                    <h2>{{ $t("home.ysk") }}</h2>
                  </b-col>
                  <b-col cols="12">
                    <ul>
                      <li
                        class="selected-industry-item-home-li float-left mr-1"
                        v-for="(keyword, index) in $_.slice(
                          cras.suggestions,
                          0,
                          6
                        )"
                        :key="index"
                      >
                        <a
                          class="mr-3 selected-industry-item-home"
                          @click="addTag(keyword)"
                          href="javascript:void(0)"
                        >
                          #{{ keyword }}
                        </a>
                      </li>
                    </ul>
                  </b-col>
                </b-row>
                <hr />
                <!--jimir3x-->
                <b-row
                  class="
                    profile-card-content-plan profile-card-content-plan--extra
                  "
                >
                  <router-link
                    to="/app/profile/settings/profile/keywords"
                    class="user-header-menu-grey"
                  >
                    {{ $t("profilex.info.keywords") }}
                  </router-link>

                  <!--{{$t("buttons.upgrade")}}-->
                </b-row>
              </div>
            </b-col>
            <!--jimir3x-->
            <!--jimir3x-->
            <b-col cols="12" v-if="$_.size(matchings) > 0">
              <div class="add-connections zoom radius">
                <h2>{{ $t("home.s-con") }}</h2>
                <hr />
                <transition-group
                  name="custom-classes-transition"
                  enter-active-class="animated"
                  leave-active-class="animated bounceOutRight"
                >
                  <b-row
                    v-for="member in matchings"
                    :key="member.id"
                    class="mb-3"
                  >
                    <b-col cols="3">
                      <b-avatar
                        variant="info"
                        :src="member.avatar"
                        class="img-fluid"
                        size="50"
                      >
                      </b-avatar>
                    </b-col>
                    <b-col class="float-left" cols="7">
                      <h3 style="text-transform: capitalize">
                       <a href="#" @click.prevent="getUserProfile(member.uid)" >  {{ member.name_surname }} </a>
                      </h3>
                      <h4 style="text-transform: uppercase">
                        {{ member.company.name }}
                      </h4>
                    </b-col>
                    <b-col cols="2">
                      <a href="javascript:void(0)" @click="follow(member.uid)">
                        <img
                          src="/img/add-connection-plus.svg"
                          class="img-fluid"
                          alt=""
                        />
                      </a>
                    </b-col>
                  </b-row>
                </transition-group>
                <hr />
                <!--jimir3x-->
                <b-row
                  class="
                    profile-card-content-plan profile-card-content-plan--extra
                  "
                >
                  <router-link
                    to="/app/my/connections"
                    class="user-header-menu-grey"
                  >
                    {{ $t("headermenu.connections") }}
                  </router-link>

                  <!--{{$t("buttons.upgrade")}}-->
                </b-row>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col xl="9" lg="9" md="9" sm="12">
          <b-row
            v-if="recommendShow"
            id="recommendSection"
            style="display: flex"
          >
            <b-col cols="6">
              <b-row>
                <b-col cols="12">
                  <div class="your-recommendations zoom radius">
                    <b-alert show="show" class="recommendations-step mb-1">
                      <router-link to="app/profile/settings/verification">
                        <b-row>
                          <b-col cols="11">
                            <p class="text-one-verified">
                              {{ $t("home.verified") }}
                            </p>
                            <p class="text-two-verified">
                              {{ $t("home.p1") }}
                            </p>
                          </b-col>
                          <b-col cols="1" class="pt-2">
                            <b-img
                              v-if="onboardingTasks.verification"
                              src="/img/home-okey.png"
                            >
                            </b-img>
                            <b-img v-else src="/img/home-right.png"> </b-img>
                          </b-col>
                        </b-row>
                      </router-link>
                    </b-alert>

                    <b-alert show="show" class="recommendations-step mb-1">
                      <a href="javascript:void(0)" @click="openAddItemModal()">
                        <b-row>
                          <b-col cols="11">
                            <p class="text-one-verified">
                              {{ $t("home.create-seller") }} /
                              {{ $t("home.create-buyer") }}
                            </p>
                            <p class="text-two-verified">
                              {{ $t("home.p-b&s") }}
                            </p>
                          </b-col>
                          <b-col cols="1" class="pt-2">
                            <b-img
                              v-if="onboardingTasks.assets"
                              src="/img/home-okey.png"
                            >
                            </b-img>
                            <b-img v-else src="/img/home-right.png"> </b-img>
                          </b-col>
                        </b-row>
                      </a>
                    </b-alert>

                    <b-alert show="show" class="recommendations-step">
                      <router-link to="/app/profile/settings/profile/company">
                        <b-row>
                          <b-col cols="11">
                            <p class="text-one-verified">
                              {{ $t("home.c-profile") }}
                            </p>
                            <p class="text-two-verified">
                              {{ $t("home.p1-c") }}
                            </p>
                          </b-col>
                          <b-col cols="1" class="pt-2">
                            <b-img
                              v-if="onboardingTasks.company"
                              src="/img/home-okey.png"
                            >
                            </b-img>
                            <b-img v-else src="/img/home-right.png"> </b-img>
                          </b-col>
                        </b-row>
                      </router-link>
                    </b-alert>
                  </div>
                  <b-progress
                    :value="onboardingTasksPercent"
                    :max="100"
                    class="recommend-progress-bar"
                  ></b-progress>
                </b-col>
              </b-row>
            </b-col>

            <b-col cols="6">
              <b-row>
                <b-col cols="12">
                 
                  <div class="chart radius" style="transform: scale(1)">
                    <chart
                      v-if="$_.size(months) > 0"
                      :chart-data="chartData"
                      :height="190"
                      :options="chartopts"
                    >
                    </chart>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <!--jimir3x-->
          <b-row
            class="timeline zoom"
            style="position: sticky; top: 70px; z-index: 10"
          >
            <!--jimir3x-->
            <b-col cols="12" class="status-share">
              <div class="status-share-content">
                <b-row vertical-align="center">
                  <b-col cols="1" class="pt-2">
                    <h3>{{ $t("home.status") }}</h3>
                  </b-col>
                  <b-col cols="8">
                    <b-form-input
                      class="status-share-input"
                      :value="form.status"
                      type="text"
                      @click="openShareModal()"
                      :placeholder="$t('home.modal.share0')"
                      required="required"
                    >
                    </b-form-input>
                  </b-col>
                  <!-- <b-col cols="8" class="hidden-status">
                    <b-form-input
                      class="status-share-input"
                      :value="form.status"
                      type="text"
                      @click="openShareModal()"
                      :placeholder="$t('home.modal.share0')"
                      required="required"
                    >
                    </b-form-input>
                 
                  </b-col> -->
                  <b-col cols="3" class="text-right">
                    <a
                      href="javascript:void(0)"
                      @click="
                        () => {
                          mediaSelect('.jpeg, .jpg, .png');
                          openShareModal();
                        }
                      "
                    >
                      <img
                        src="/img/status-camera.svg"
                        class="img-fluid mr-4"
                        alt=""
                      />
                    </a>
                    <a
                      href="javascript:void(0)"
                      @click="
                        () => {
                          mediaSelect('.mp4, .webm');
                          openShareModal();
                        }
                      "
                    >
                      <img
                        src="/img/status-video.svg"
                        class="img-fluid"
                        alt=""
                      />
                    </a>
                  </b-col>
                </b-row>
              </div>
            </b-col>
            <b-col cols="12" class="timeline-filter">
              <div class="status-share-content status-share-content--extra">
                <b-row>
                  <div class="matching-filter-left" style="display: flex">
                    <h3 class="post-info color-gray">
                      {{ $t("home.bar.new") }}
                    </h3>

                    <h3 class="post-info">
                      {{ $t("home.bar.posts") }}
                      <span class="post-info-indicator ml-3">
                        <Counter :number="unviewedPostNumbers['STATUS']" />
                      </span>
                    </h3>

                    <h3 class="post-info">
                      {{ $t("home.bar.insights") }}
                      <span class="post-info-indicator ml-3">
                        <Counter :number="unviewedPostNumbers['INSIGHT']" />
                      </span>
                    </h3>


                    <h3 class="post-info">
                      {{ $t("home.bar.requests") }}
                      <span class="post-info-indicator ml-3">
                        <Counter
                          :number="
                            unviewedPostNumbers['INQUIRY']
                          "
                        />
                      </span>
                    </h3>

                    <h3 class="post-info">
                      {{ $t("home.bar.quotes") }}
                      <span class="post-info-indicator ml-3">
                        <Counter
                          :number="
                            unviewedPostNumbers['PRODUCT']
                          "
                        />
                      </span>
                    </h3>
                  </div>

                  <div class="ghost-div"></div>

                  <!--jimir3x-->
                  <div class="status-share-content__filter-wrapper">
                    <h6 style="font-size: 12px" class="color-gray">
                      {{ $t("home.bar.filter") }}:
                    </h6>
                    <b-form-group>
                      <b-form-select
                        size="sm"
                        class="timeline-filter-box"
                        :value="null"
                        @change="typeChange"
                      >
                        <b-form-select-option :value="null" selected>
                          {{ $t("home.bar.all") }}</b-form-select-option
                        >
                        <b-form-select-option value="PRODUCT">
                          {{ $t("home.bar.quotes") }}</b-form-select-option
                        >
                        <b-form-select-option value="INQUIRY">
                          {{ $t("home.bar.requests") }}</b-form-select-option
                        >
                        <b-form-select-option value="STATUS">
                          {{ $t("home.bar.status") }}</b-form-select-option
                        >
                        <b-form-select-option value="INSIGHT">
                          {{ $t("home.bar.insights") }}</b-form-select-option
                        >
                        <b-form-select-option value="PIN">
                          {{ $t("home.bar.pinned") }}</b-form-select-option
                        >
                      </b-form-select>
                    </b-form-group>
                  </div>
                  <!--jimir3x-->
                </b-row>
              </div>
            </b-col>
          </b-row>
          <sweet-modal
            class="modal-x modal-x--extra zoom"
            id="shareStatusModal"
            ref="shareStatus"
            @close="closeModal()"
            @open="shareModalOpened()"
            width=""
          >
            <div class="share-status-post-box">
              <div class="row">
                <div class="col-md-12 text-left">
                  <h2>{{ $t("home.share-status") }}</h2>
                </div>
              </div>
              <hr />
              <div class="row mt-4">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-md-3">
                      <b-avatar
                        variant="info"
                        :src="GET_USER_INFO.avatar"
                        class="img-fluid"
                        size="70"
                      >
                      </b-avatar>
                    </div>
                    <div class="col-md-9">
                      <h3>
                        {{ GET_USER_INFO.name }}
                        {{ GET_USER_INFO.surname }}
                      </h3>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 d-flex">
                  <div class="row">
                    <div class="col-md-12">
                      <h4>{{ $t("home.modal.share") }}</h4>
                        <div
                            class="share-modal-socials"
                            v-for="(item, index) in actplatforms"
                            :key="'platform-' + index"
                        >
                            <input 
                                type="checkbox" 
                                name="social" 
                                :id="'platform-' + index + '-checkbox'"
                                :disabled="item.disabled"
                                :value="item.value"
                                v-model="form.share"
                            >
                            <label 
                                class="share-social-label"
                                :for="'platform-' + index + '-checkbox'"
                            >
                                {{ item.text }}
                            </label>
                            <a 
                                href="#"
                                @click.prevent="authSocial(item.value)"
                                v-if="item.disabled" 
                                class="reconnect-social"
                            >
                                <svg style="width:20x; height:20px; color:#d9d9d9;" viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M21.4 7.5C22.2 8.3 22.2 9.6 21.4 10.3L18.6 13.1L10.8 5.3L13.6 2.5C14.4 1.7 15.7 1.7 16.4 2.5L18.2 4.3L21.2 1.3L22.6 2.7L19.6 5.7L21.4 7.5M15.6 13.3L14.2 11.9L11.4 14.7L9.3 12.6L12.1 9.8L10.7 8.4L7.9 11.2L6.4 9.8L3.6 12.6C2.8 13.4 2.8 14.7 3.6 15.4L5.4 17.2L1.4 21.2L2.8 22.6L6.8 18.6L8.6 20.4C9.4 21.2 10.7 21.2 11.4 20.4L14.2 17.6L12.8 16.2L15.6 13.3Z" />
                                </svg>
                            </a>
                            <a 
                                v-else 
                                class="reconnect-social"
                            >
                                 <svg style="width:24px;height:24px; color:#05ff00;" viewBox="0 0 24 24">
    <path fill="currentColor" d="M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2,4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z" />
</svg>
                            </a>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <b-progress
                    v-if="form.percentage !== null"
                    :value="form.percentage"
                    :max="100"
                    variant="primary"
                    show-progress
                    animated
                    class="mb-3"
                  >
                  </b-progress>
                  <b-form-textarea
                    id="textarea"
                    class=""
                    v-model="form.status"
                    :placeholder="$t('home.modal.p-holder')"
                    rows="3"
                    max-rows="6"
                  >
                  </b-form-textarea>
                </div>
              </div>
              <member-repost-in-modal v-if="form.parent" :post="form.parent" />
              <b-row v-if="$_.size(mediaPreviews) > 0">
                <b-col cols="12">
                  <!--jimir3x-->
                  <div
                    class="
                      sending-file-div-home sending-file-div-home--extra
                      d-flex
                      row
                    "
                  >
                    <!--jimir3x-->
                    <div
                      v-for="(item, index) in mediaPreviews"
                      :key="index"
                      class="uploading-item col"
                    >
                      <video 
                        v-if="['video/mpeg','video/webm', 'video/mp4'].includes(item.type)" 
                        controls 
                        width="100"
                        height="100"
                    >
                        <source :src="item.preview" type="audio/mpeg" />
                      </video>
                      <img
                        style="object-fit: cover"
                        v-else
                        :src="item.preview"
                        width="100"
                      />
                      <p class="sending-file-name">
                        {{ item.name }}
                      </p>

                      <a
                        @click="() => form.media.splice(index, 1)"
                        class="uploading-img-delete"
                      >
                        <img src="/img/cross.svg" alt="" class="img-fluid" />
                      </a>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="post-status post-status--extra">
              <div class="row">
                <div class="col-md-6 text-left">
                  <b-form-file
                    :accept="mediaAccept"
                    style="display: none"
                    v-model="form.media"
                    multiple
                    ref="mselector"
                  >
                  </b-form-file>
                  <a
                    href="javascript:void(0)"
                    @click="mediaSelect('.jpeg, .jpg, .png')"
                  >
                    <img
                      src="/img/status-camera.svg"
                      class="img-fluid mr-4"
                      alt=""
                    />
                  </a>
                  <a
                    href="javascript:void(0)"
                    @click="mediaSelect('.mp4, .webm')"
                  >
                    <img src="/img/status-video.svg" class="img-fluid" alt="" />
                  </a>
                </div>
                <div class="col-md-6 text-right">
                  <b-button
                    :disabled="isDisabledPostButton"
                    @click="newPost()"
                    class="status-share-button"
                    >{{ $t("buttons.post") }}</b-button
                  >
                </div>
              </div>
            </div>
          </sweet-modal>

          <div class="main-scroll zoom">
            <DynamicScroller
              :style="scrollerStyle"
              :key="scrollerKey"
              :items="posts"
              :buffer="1000"
              :minItemSize="300"
              :emitUpdate="true"
              @update="onScroll"
              id="scroller"
              ref="scroller"
              keyField="id"
              typeField="ok"
            >
              <template #before>
                  <Welcome v-if="welcomeDoNotShowAgain === false" />
              </template>
             <template v-slot="{ item, index, active }">
                <DynamicScrollerItem
                    v-if="item"
                    :item="item"
                    :active="active"
                    :dataIndex="index"
                >
                    <member-post
                        :key="item.id"
                        :id="'post-' + item.id"
                        :eventBus="seb"
                        :post="item"
                        :interactionBar="true"
                        :showAuthor="true"
                        @pin="pin"
                        @unpin="unpin"
                        @like="like"
                        @unlike="unlike"
                        @setParent="setParent"
                        @showPostImageModal="showPostImageModal"
                        @remove="remove"
                        @share="share"
                    />
                </DynamicScrollerItem>
             </template>
            </DynamicScroller>
          </div>

          <!--jimir3x-->
          <div
            v-if="postImageModal.visible"
            id="post-image-modal"
            @click="onCLickPostImageModal"
          >
            <div
              class="post-image-modal__close"
              @click="onCLickPostImageModalCross"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill="#000000"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path
                  d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"
                />
              </svg>
            </div>
            <div
              v-if="postImageModal.files.length > 0"
              class="post-image-modal__inner"
            >
              <!--Prev-->
              <div
                id="post-image-modal__prev-btn"
                class="post-image-modal__prev-btn"
                v-bind:class="{ disabled: postImageModal.index == 0 }"
                @click="onClickPrev"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="#000000"
                >
                  <path d="M0 0h24v24H0V0z" fill="none" opacity=".87"></path>
                  <path
                    d="M16.62 2.99c-.49-.49-1.28-.49-1.77 0L6.54 11.3c-.39.39-.39 1.02 0 1.41l8.31 8.31c.49.49 1.28.49 1.77 0s.49-1.28 0-1.77L9.38 12l7.25-7.25c.48-.48.48-1.28-.01-1.76z"
                  ></path>
                </svg>
              </div>
              <!--Next-->
              <div
                id="post-image-modal__next-btn"
                class="post-image-modal__next-btn"
                v-bind:class="{
                  disabled:
                    postImageModal.index == postImageModal.files.length - 1,
                }"
                @click="onClickNext"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  fill="#000000"
                >
                  <path d="M24 24H0V0h24v24z" fill="none" opacity=".87"></path>
                  <path
                    d="M7.38 21.01c.49.49 1.28.49 1.77 0l8.31-8.31c.39-.39.39-1.02 0-1.41L9.15 2.98c-.49-.49-1.28-.49-1.77 0s-.49 1.28 0 1.77L14.62 12l-7.25 7.25c-.48.48-.48 1.28.01 1.76z"
                  ></path>
                </svg>
              </div>

              <img
                v-if="
                  ['jpeg', 'jpg', 'png'].includes(
                    postImageModal.files[postImageModal.index].ext
                  )
                "
                :src="
                  CDN_IMAGE_PREFIX +
                  postImageModal.files[postImageModal.index].code +
                  '.jpg'
                "
              />
              <video
                v-else-if="
                  ['webm', 'mp4'].includes(
                    postImageModal.files[postImageModal.index].ext
                  )
                "
                style="width: 100%; border-radius: 6px"
                controls
              >
                <source
                  :src="
                    CDN_VIDEO_PREFIX +
                    postImageModal.files[postImageModal.index].code
                  "
                />
              </video>
              <audio
                v-else-if="
                  ['mp3'].includes(
                    postImageModal.files[postImageModal.index].ext
                  )
                "
                controls
              >
                <source
                  :src="
                    CDN_VIDEO_PREFIX +
                    postImageModal.files[postImageModal.index].code
                  "
                  type="audio/mpeg"
                />
              </audio>
            </div>
          </div>
          <!--jimir3x-->
        </b-col>
      </b-row>
    </b-container>
    <sweet-modal
      class="zoom modal-x"
      icon="/img/switch-account.svg"
      ref="removeModal"
      id="deletePost"
    >
      {{ $t("home.modal.delete-post") }}
      <template #button>
        <button
          class="btn btn-primary"
          @click="
            () => {
              $refs.removeModal.$data.remove();
              $refs.removeModal.close();
            }
          "
        >
          {{ $t("home.modal.yes") }}
        </button>
      </template>
    </sweet-modal>
    <ShareModal ref="shareSocialModal"/>
  </div>
</template>
<script>
import Header from "@/components/app/Header.vue";
import MemberPost from "@/components/home/member-post.vue";
import ShareModal from "@/components/home/share-modal.vue";
import MemberRepostInModal from "@/components/home/member-repost-in-modal.vue";
import Welcome from "@/components/home/welcome.vue";
import chart from "@/plugins/line-chart";
import { mapGetters } from "vuex";
import HumanReadableTime from "@/components/global/HumanReadableTime.vue";
import Counter from "@/components/global/Counter.vue";
import _ from "lodash";
import moment from "moment";
import Vue from "vue";
export default {
  components: {
    Header,
    HumanReadableTime,
    chart,
    MemberPost,
    Welcome,
    MemberRepostInModal,
    Counter,
    ShareModal
  },
  data() {
    return {
      typeofuser: window.localStorage.getItem("user_type"),
      mediaAccept: "",
      scrollerKey: 1,
      scrollerStyle: {
        overflowY: "scroll !important",
        height: "200vh !important",
      },
      form: {
        parent_id: null,
        status: "",
        media: [],
        percentage: null,
        files: [],
        share: []
      },
      type: "SELLER",
      selectedPlatforms: [],
      chartopts: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: "🚀 🚀 🚀 ",
          position: "top",
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
      platforms: [
        {
          text: "Linkedin",
          value: "LINKEDIN",
          enabled: false,
        },
        {
          text: "Twitter",
          value: "TWITTER",
          enabled: false,
        }
      ],
      popoverConfig: {
        html: true,
        content:
          "Connect your social accounts in your profile section to share your posts in other platforms. Account Settings > Profile > Share > Connect.",
      },
      recommendShow: false,
      seb: new Vue(),
      /*jimir3x*/
      postImageModal: {
        visible: false,
        files: [],
        index: 0,
      },
      /*jimir3x*/
    };
  },
  watch: {
      recommendShow(val) {
          localStorage.setItem('landing:recommended', val);
      }
  },
  computed: {
    ...mapGetters("landing/post", [
      "posts",
      "loadings",
      "unviewedPostNumbers",
      "welcomeDoNotShowAgain",
    ]),
    ...mapGetters("landing/features", {
      assets: "variablesAssets",
      plan: "variablesPlan",
      cras: "variablesCras",
      matchings: "variablesMatchings",
      months: "variablesChartOfYear",
      featureLoadings: "variablesLoadings",
      socialAccounts: "variablesSocialAccounts",
    }),
    mediaPreviews() {
      if (_.size(this.form.media) === 0) {
        return [];
      }

      let previews = [];
      for (let item of this.form.media) {
        switch (item.type) {
          case "image/jpeg":
          case "image/jpg":
          case "image/png":
            previews.push({
              name: item.name,
              preview: URL.createObjectURL(item),
              type: item.type,
            });
            break;

          case "video/webm":
          case "video/mp4":
          case "audio/mpeg":
            previews.push({
              name: item.name,
              preview: URL.createObjectURL(item),
              type: item.type,
            });
            break;

          default:
            previews.push({
              name: item.name,
              preview: require("@/assets/img/file.png"),
              type: item.type,
            });
            break;
        }
      }
      return previews;
    },
    onboardingTasks() {
      return {
        verification: !_.isNil(_.get(this, "GET_USER_INFO.verification", null)),
        assets: this.assets.products + this.assets.inquiries >= 3,
        company: !_.isNil(_.get(this, "GET_USER_INFO.company.website", null)),
      };
    },
    onboardingTasksPercent() {
      let size = _.size(this.onboardingTasks);
      let percentPerTask = 100 / size;
      let percent = 0;
      for (let key in this.onboardingTasks) {
        if (this.onboardingTasks[key] == true) {
          percent += percentPerTask;
        }
      }

      return _.toNumber(percent.toFixed(1));
    },
    chartData() {
      let data = { labels: [], datasets: [] };
      if (_.size(this.months) === 0) {
        return data;
      }

      for (let i = 1; i <= 12; i++) {
        data.labels.push(moment(i, "M").format("MMM"));
      }

      data.datasets.push({
        label: "SELLERS",
        data: _.map(
          _.filter(this.months, { type: "SELLER" }),
          (item) => item.count
        ),
        borderColor: "#6A87A4",
        backgroundColor: "rgba(0, 0, 0, 0)",
        pointHoverBorderWidth: 1,
        borderWidth: 0.7,
      });

      data.datasets.push({
        label: "BUYERS",
        data: _.map(
          _.filter(this.months, { type: "BUYER" }),
          (item) => item.count
        ),
        borderColor: "#198cff",
        backgroundColor: "rgba(0, 0, 0, 0)",
        pointHoverBorderWidth: 1,
        borderWidth: 0.7,
      });

      return data;
    },
    actplatforms() {
      return _.map(
          this.platforms, 
          x => (
              {
                ...x,
                disabled: !_.find(this.socialAccounts, { type: x.value }),
              }
          )
      );
    },
    isDisabledPostButton() {
      let cond =
        _.isEmpty(_.trim(this.form.status)) &&
        _.isNil(this.form.parent_id) &&
        _.size(this.form.media) === 0;

      if (this.loadings.mutate) {
        return false;
      }

      return cond;
    },
  },
  methods: {
    mediaSelect(accept) {
      this.mediaAccept = accept;
      this.$nextTick(() =>
        this.$refs.mselector.$el.querySelector('input[type="file"]').click()
      );
    },
    openShareModal() {
      this.$refs.shareStatus.open();
    },

    async share(id) {
        this.$refs.shareSocialModal.open(id);
    },

    async follow(uid) {
      await this.$store.dispatch("landing/features/FOLLOW", uid);
      if (this.matchings === 0) {
        this.$store.dispatch(
          "landing/features/FETCH_MATCHINGS",
          this.typeofuser
        );
      }
    },

    onScroll(e) {
      this.seb.$emit("scroll", true);
      let el = document.querySelector("#scroller");
      let diff =
        Math.floor(el.scrollHeight - el.offsetHeight) -
        Math.floor(el.scrollTop);
      if (diff <= 550 && this.loadings.query === false) {
        this.$store.dispatch("landing/post/LOAD_NEXT");
      }
    },

    like(id) {
      this.$store.dispatch("landing/post/LIKE", id);
    },

    remove(id) {
      this.$refs.removeModal.open();
      this.$refs.removeModal.$data.remove = () =>
        this.$store.dispatch("landing/post/REMOVE_POST", id);
    },

    addTag(tag) {
      this.$store.dispatch("landing/features/ADD_TAG", tag);
    },

    unlike(id) {
      this.$store.dispatch("landing/post/UNLIKE", id);
    },

    pin(id) {
      this.$store.dispatch("landing/post/PIN", id);
    },

    unpin(id) {
      this.$store.dispatch("landing/post/UNPIN", id);
    },

    async newPost() {
      if (
        _.size(this.form.status) === 0 &&
        _.size(this.form.media) === 0 &&
        this.form.parent === null
      ) {
        return this.$bvToast.toast(this.$t("Toaster.empty-text-area"), {
          title: this.$t("Toaster.error-title"),
          variant: "danger",
          autoHideDelay: 1350,
        });
      }

      let options = {
        fetchOptions: {
          onUploadProgress: (progress) => {
            this.form.percentage = Math.round(
              (progress.loaded / progress.total) * 100
            );
          },
        },
      };

      if (_.size(this.form.media) > 0) {
        this.form.percentage = 0;
        let files = await this.$store.dispatch("landing/post/UPLOAD_FILES", {
          files: this.form.media,
          options: options,
        });

        for (let index in files) {
          if (files[index].uploaded === false) {
            alert(files[index].code);
            this.files.splice(index, 1);
            files.splice(index, 1);
          }

          if (files[index]) {
            this.form.files.push({
              code: files[index].code,
              ext: files[index].ext,
              filename: files[index].filename,
            });
          }
        }
        this.form.percentage = null;
      }

      try {
        await this.$store.dispatch("landing/post/NEW_POST", {
          message: this.form.status,
          files: this.form.files,
          reference_id: this.form.parent ? this.form.parent.id : null
        });

        if (this.form.parent) {
          this.$store.commit("landing/post/REPOSTED", this.form.parent.id);
        }

        this.form.status = "";
        this.form.parent = null;
        this.$set(this.form, "files", []);
        this.$set(this.form, "media", []);
        this.$bvToast.toast(this.$t("Toaster.post-success"), {
          title: this.$t("Toaster.success-title"),
          variant: "success",
          autoHideDelay: 1350,
        });
        await this.$nextTick();
        this.$refs.scroller.scrollToItem(0);
      } catch (error) {
        this.$bvToast.toast(this.$t(error.message), {
          title: this.$t("Toaster.error-title"),
          variant: "danger",
          aautoHideDelay: 1350,
        });
      } finally {
        this.$refs.shareStatus.close();
      }
    },

    async setParent(parent) {
      this.form.parent = parent;
      this.openShareModal();
      this.$forceUpdate();      
    },

    closeModal() {
      this.form.parent = null;
      this.form.status = "";
    },

    shareModalOpened() {
      this.$store.dispatch("landing/features/LOAD_SOCIAL_ACCOUNTS");
    },

    authSocial(type) {
      let popup = window.open(
          `${this.API_DOMAIN}/auth/${_.toLower(type)}?token=${this.GET_USER_INFO.token}`, 
          '_blank',
          'status=1,width=500,height=300'
      );

      let id = null;
      let timer = async () => {
        if (popup && popup.closed) {
          clearInterval(id);
          this.shareModalOpened();
          popup = null;
        }
      };

      id = setInterval(timer, 250);
    },

    async typeChange(type) {
      if (type == "PIN") {
        this.$store.commit("landing/post/SET_FILTERS", {
          pinned: true,
        });
      }

      if (type !== "PIN") {
        this.$store.commit("landing/post/SET_FILTERS", { type });
      }

      await this.$store.dispatch("landing/post/LOAD_NEXT");
      this.scrollerKey++;
    },

    /*jimir3x*/
    onCLickPostImageModal(e) {
      const { target } = e;
      if (target?.id == "post-image-modal") {
        this.postImageModal.visible = false;
      }
    },
    onCLickPostImageModalCross() {
      this.postImageModal.visible = false;
    },
    showPostImageModal({ files, index }) {
      this.postImageModal = { visible: true, files, index };
    },

    onClickPrev() {
      this.postImageModal.index = this.postImageModal.index - 1;
    },

    onClickNext() {
      this.postImageModal.index = this.postImageModal.index + 1;
    },

    gotoMyQuotes() {
      window.localStorage.setItem("user_type", "SELLER");
      this.$router.push("/app/my/listings");
    },

    gotoMyRequests() {
      window.localStorage.setItem("user_type", "BUYER");
      this.$router.push("/app/my/requests");
    },

    onScrollSticky(e) {
      const { scrollTop, offsetHeight, scrollHeight } = e.target;
      const stickyElement = document.querySelector(".row.landing-row-sticky");
      if (scrollTop + offsetHeight > scrollHeight) {
        stickyElement.style.width = "305px";
        stickyElement.style.position = "fixed";
        stickyElement.style.top = "56px";
      } else {
        stickyElement.style.position = "static";
        stickyElement.style.width = "auto";
      }
    },
    onPostImageModalKeydown(e) {
      if (this.postImageModal.visible) {
        const { index, files } = this.postImageModal;

        if (e.key == "ArrowRight" && index < files.length - 1)
          this.onClickNext();
        else if (e.key == "ArrowLeft" && index > 0) this.onClickPrev();
      }
    },
    /*jimir3x*/
    goToMatches(type) {
        window.localStorage.setItem('user_type', type);
        this.$router.push('/app/dashboard');
    }
  },
  async created() {
    let recommend = localStorage.getItem('landing:recommended');
    this.recommendShow = recommend != null ? (recommend === 'true' ? true : false) : false;
    this.$store.commit("landing/post/RESET_POSTS");
    this.$store.dispatch("landing/post/LOAD_NEXT");
    this.$store.commit("landing/features/SET_PARAMS_ASSETS", {
      uid: this.GET_USER_INFO.uid,
      type: this.typeofuser,
    });
    this.$store.dispatch("landing/features/COUNT_ASSETS");
    this.$store.dispatch("landing/features/CHECK_MY_PLAN");
    this.$store.dispatch("landing/post/COUNT_UNVIEWED_POSTS");
    this.$store
      .dispatch("landing/features/GET_TAGS")
      .then(() => this.$store.dispatch("landing/features/CALC_CRAS"));
    this.$store.dispatch("landing/features/FETCH_MATCHINGS", this.typeofuser);
    this.$store.dispatch("landing/features/FETCH_CHART_DATA", {
      year: new Date().getFullYear(),
    });

    this.$store.dispatch("landing/post/GET_DO_NOT_SHOW_AGAIN");
  },
  mounted() {
    window.addEventListener("keydown", this.onPostImageModalKeydown);

    /*jimir3x*/
    //const idApp = document.getElementById("app");
    //idApp.addEventListener("scroll", this.onScrollSticky);
    /*jimir3x*/

    // document
    //   .getElementById('post-image-modal')
    //   .addEventListener('click', this.onCLickPostImageModal);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.onPostImageModalKeydown);

    /*jimir3x*/
    //const idApp = document.getElementById("app");
    //idApp.removeEventListener("scroll", this.onScrollSticky);
    /*jimir3x*/

    // document
    //   .getElementById('post-image-modal')
    //   .removeEventListener('click', this.onCLickPostImageModal);
  },
};
</script>