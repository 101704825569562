<template>
    <sweet-modal class="modal-x" ref="modal" id="shareModalSocial">
        <div >
            <div 
                class="social-box"
                v-for="(item, index) in actplatforms"
                :key="'splatform-' + index"
            >
                <input 
                    :disabled="item.disabled" 
                    :id="'platform-' + index" 
                    type="checkbox"
                    :value="item.value"
                    v-model="type" 
                />
                <span class="check"></span>
                <label style="padding-left: 70px" :for="'platform-' + index">
                    <img
                        class="float-left no-not"
                        :src="item.img" 
                        :class="item.class"
                    />
                </label>
                <a 
                    href="#"
                    class="social-reconnect"
                    @click.prevent="authSocial(item.value)"
                    v-if="item.disabled" 
                >
                    <svg style="width:20x; height:20px; color:#d9d9d9;" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M21.4 7.5C22.2 8.3 22.2 9.6 21.4 10.3L18.6 13.1L10.8 5.3L13.6 2.5C14.4 1.7 15.7 1.7 16.4 2.5L18.2 4.3L21.2 1.3L22.6 2.7L19.6 5.7L21.4 7.5M15.6 13.3L14.2 11.9L11.4 14.7L9.3 12.6L12.1 9.8L10.7 8.4L7.9 11.2L6.4 9.8L3.6 12.6C2.8 13.4 2.8 14.7 3.6 15.4L5.4 17.2L1.4 21.2L2.8 22.6L6.8 18.6L8.6 20.4C9.4 21.2 10.7 21.2 11.4 20.4L14.2 17.6L12.8 16.2L15.6 13.3Z" />
                    </svg>
                </a>
                <a 
                    href="#"
                    class="social-reconnect"
                    v-else 
                >
                   <svg style="width:24px;height:24px; color:#05ff00;" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2,4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z" />
                    </svg>
                </a>
           </div>
        
        </div>

        <hr style="margin-top: 20px" />
        <b-button
            style="
                padding: 5px 20px;
                margin-top: 0px;
                font-size: 15px;
                border-radius: 3px !important;
            "
            variant="primary"
            :disabled="loading === true || $_.size(type) === 0"
            @click="share()"
        >
            <b-spinner v-if="loading === true" variant="primary" small></b-spinner>
            {{ $t("profile.titles.subtab4") }}
        </b-button>
    </sweet-modal>
</template>
<script>
import { mapGetters } from 'vuex';
import SHARE_POST from '@/graphql/landing/sharePost.graphql';
import _ from 'lodash';
export default {
    data() {
        return {
            loading: false,
            accounts: [],
            id: null,
            type: [],
            platforms: [
                {
                    text: "Linkedin",
                    value: "LINKEDIN",
                    class: "linkedin-logo-share",
                    enabled: false,
                    img: '/img/linkedin-logo.png'
                },
                {
                    text: "Twitter",
                    value: "TWITTER",
                    class: "twitter-logo-share",
                    enabled: false,
                    img: '/img/twitter-logo.png'
                }
            ],
        };
    },
    computed: {
        ...mapGetters(
            "landing/features", 
            {
                socialAccounts: "variablesSocialAccounts",
            }
        ),
        actplatforms() {
            return (
                _.map(
                    this.platforms, 
                    x => (
                        {
                            ...x,
                            disabled: !_.find(this.socialAccounts, { type: x.value })
                        }
                    )
                )
            );
        }
    },
    methods: {
        open(id) {
            this.id = id;
            this.$refs.modal.open();
            this.loadSocialAccounts();
        },
        
        authSocial(type) {
            let popup = window.open(
                `${this.API_DOMAIN}/auth/${_.toLower(type)}?token=${this.GET_USER_INFO.token}`, 
                '_blank',
                'status=1,width=500,height=300'
            );

            let id = null;
            let timer = async () => {
                if (popup && popup.closed) {
                    clearInterval(id);
                    this.loadSocialAccounts();
                    popup = null;
                }
            };

            id = setInterval(timer, 250);
        },
        
        loadSocialAccounts() {
            this.$store.dispatch("landing/features/LOAD_SOCIAL_ACCOUNTS");
        },

        async share() {
            try {
                this.loading = true;
                await this.$apollo.mutate(SHARE_POST, { type: this.type, id: this.id });
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
                this.$refs.modal.close();
            }
        }
    }
};
</script>